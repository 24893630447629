<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="equip.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="LBL0001720" :value="equip.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="LBL0001727" :value="equip.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="LBL0001725" :value="equip.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 설비 사양 -->
      <c-table
        ref="grid"
        title="LBL0003288"
        tableId="grid"
        :columns="grid.columns"
        :data="equip.specs"
        selection="multiple"
        rowKey="equipmentSpecId"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
              <!-- 추가 -->
              <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrow" />
              <!-- 삭제 -->
              <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteSpec" />
              <!-- 저장 -->
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveSpec" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-spec',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
      }),
    },
    equip: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        subProcessCd: '',  // 부공정
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        amount: '',  // 수량
        specs: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'item',
            field: 'item',
            // 항목
            label: 'LBLITEMS',
            style: 'width:100px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            // 항목설명
            label: 'LBL0003312',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemValue',
            field: 'itemValue',
            // 값
            label: 'LBL0003313',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            // 단위
            label: 'LBLUNIT',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.spec.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.spec.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.spec.delete.url;
      // code setting
      // list setting
    },
    getList() {
      if (this.isOld) {
        this.$http.url = this.listUrl;
        this.$http.param = {
          equipmentCd: this.popupParam.equipmentCd
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.equip.specs = _result.data;
        },);
      }
    },
    saveSpec() {
      if (this.$comm.validTable(this.grid.columns, this.equip.specs)) {
        let saveData = this.equip.specs.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.equip.specs.push({
        equipmentSpecId: uid(),
        equipmentCd: this.popupParam.equipmentCd,
        item: '',
        itemDesc: '',
        itemValue: '',
        itemUnit: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    deleteSpec() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.equip.specs = this.$_.reject(this.equip.specs, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>